<script>
    import Monitor from '@/services/Monitor';
    import General from '@/services/General';
    import validationMessages from '@/components/validations';
    import { required, maxLength } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                showLoader: false,
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                tenants:'',
                tenant: null ,
                allTenant: '' ,
                filteredTenants: [],
                tenantDashboard: [] ,
                isFilteringComplete: false,
                dashboard_url : '',
                dashboard_version : '',
                tenants_options:[],
                Data: [],
                csrf_token: localStorage.getItem('csrf_token'),
               
            };
        },
        validations: {
            tenant: {
                required,
            },
            dashboard_url: {
                required,
                maxLength: maxLength(100),
            }
        },
      methods: {
        refreshData() {
          this.$emit('onRefresh');
        },

        async getTenants() {
          try {
            this.showLoader = true;
            const response = await Monitor.getTenants();
            this.tenants_options = response.data.tenants.map(tenant => ({
              text: tenant,
              value: tenant,
            }));
          } catch (error) {
            console.log('Error fetching tenants:', error);
          } finally {
            this.showLoader = false;
          }
        },

        async getTenantDashboards() {
          try {
            this.showLoader = true;
            const response = await Monitor.getTenantDashboards();
            if (response && response.data) {
              this.tenantDashboard = response.data.map(item => item.tenant);
            }
          } catch (error) {
            console.error('Error fetching tenant dashboards:', error);
            this.error = error.response?.data?.error || '';
          }
        },

        filterTenants() {
          this.showLoader = true;
          if (!this.tenants_options || !this.tenantDashboard) {
            return [];
          }

          this.filteredTenants = this.tenants_options.filter(tenant => {
            return !this.tenantDashboard.includes(tenant.value);
          });

          this.isFilteringComplete = true;
          this.showLoader = false;
        },

        async createTenantDashboard() {
          this.tryingToSubmit = true;
          this.submitted = true;
          this.showLoader = true;

          this.$v.$touch();

          if (this.$v.$invalid || !this.tenant) {
            this.showLoader = false;
            return;
          }

          try {
            this.showLoader = true;

            await this.getTenantDashboards();

            const requestData = {
              tenant: this.tenant,
              dashboard_url: this.dashboard_url,
            };

            const response = await Monitor.createTenantDashboard(requestData);

            this.successmsg('Tenant Dashboard Created!');
            this.refreshData();
            this.closeModal();
          } catch (error) {
            this.error = error.response?.data?.error || "An error occurred";
            this.failedmsg(this.error);
          } finally {
            this.tryingToSubmit = false;
          }
        },


        closeModal() {
          this.showModal = false;
        },

        resetProps() {
          this.submitted = false;
          this.tenant = '';
          this.dashboard_url = '';
        },

        async modalShown() {
          this.isFilteringComplete = false;
          await this.getTenants();
          await this.getTenantDashboards();
          this.filterTenants();

          if (this.isFilteringComplete) {
            this.showModal = true;
          }
        }
      },

    };
</script>

<template>
    <b-modal @shown="modalShown" id="add_new_tenant_dashboard" v-model="showModal" @hidden="resetProps" title="Add New Tenant Dashboard" title-class="font-18" size="lg">
        <form @submit.prevent="createTenantDashboard" v-if="!showLoader">
            <div class="row">
              <div class="col-4">
                <b-form-group label="Select Team ID" label-for="formrow-tenant-input" class="mb-3">
                  <b-form-select
                      v-model="tenant"
                      :options="filteredTenants"
                      :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                      class="form-select"
                  ></b-form-select>
                  <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
                </b-form-group>
              </div>


              <b-form-group label="Dashboard URL" label-for="formrow-title-input" class="mb-3">
                    <b-form-input
                            v-model.trim="dashboard_url"
                            type="text"
                            id="dashboard_url"
                            aria-describedby="title-feedback"
                            :class="{ 'is-invalid': submitted && $v.dashboard_url.$error, }"
                    >
                    </b-form-input>
                    <validationMessages v-if="submitted" :fieldName="'Dashboard URL'" :validationName="$v.dashboard_url"></validationMessages>
                </b-form-group>
            </div>
        </form>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <div class="row">
                    <div class="col-12">
                        <div class="row  align-items-center justify-content-center">
                            <b-spinner large></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createTenantDashboard" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>
